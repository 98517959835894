import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  redirect,
  useNavigate,
} from "@remix-run/react";
import { NextUIProvider } from "@nextui-org/react";
import { useNonce } from "~/utils/nonce-provider.ts";
import styles from "~/tailwind.css?url";
import { useParamsLang } from "remix-paraglidejs/client";
import {
  setLanguageTag,
  availableLanguageTags,
  sourceLanguageTag,
} from "$paraglide/runtime";
import { getLocale } from "./utils/locale.server";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const pathname = url.pathname.endsWith("/")
    ? url.pathname
    : `${url.pathname}/`;
  const isLocaleInPath = !!pathname.match(
    new RegExp(`^/${availableLanguageTags.join("|")}/`)
  );

  if (!isLocaleInPath) {
    const locale = await getLocale(request);
    return redirect(`/${locale}${pathname}`);
  }
  return null;
};

export default function App() {
  const navigate = useNavigate();
  const nonce = useNonce();
  const lang = useParamsLang("lang", availableLanguageTags, sourceLanguageTag);
  setLanguageTag(lang);

  return (
    <html className="h-full" lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <Meta />
        <Links />
      </head>
      <body>
        <NextUIProvider navigate={navigate}>
          <Outlet />
          <ScrollRestoration nonce={nonce} />
          <Scripts nonce={nonce} />
        </NextUIProvider>
      </body>
    </html>
  );
}
